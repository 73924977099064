import React from 'react'
import { navigate } from 'gatsby'
import { PropTypes } from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'
import { passwordProtected } from '../config/password-proctected'
import PageContentWrapper from '../components/pageContentWrapper'
import IncomeMixChart from '../components/charts/income-mix'
import PricePointsSaleChart from '../components/charts/price-points-sale-chart'
import PricePointsRentalChart from '../components/charts/price-points-rental-chart'
import IncomeChangeChart from '../components/charts/income-change'
import SectionUpfrontText from '../components/collapsibleUpfrontText/section-upfront-text'
import IncomeMixNote from '../components/dynamicText/prices-incomes/income-mix'
import IncomeChangeNote from '../components/dynamicText/prices-incomes/income-change'
import DynamicText from '../components/dynamicText'
import { getEntryLevelLabel } from '../functions/getLevelLabels'
import GenericPriceChangeChart from '../components/charts/generic-price-change-chart'
import DwellingChangeNote from '../components/dynamicText/prices-incomes/dwelling-price-change'
import RentPriceChangeNote from '../components/dynamicText/prices-incomes/rent-price-change'

class PricesIncomesTemplate extends React.Component {
  componentDidMount() {
    const access = _.head(_.filter(passwordProtected, ['lga', this.props.pageContext.LGASlug]))
    if (!_.isEmpty(access) && access.password !== window.pass) {
      navigate(`/${this.props.pageContext.LGASlug}`)
    }
  }

  render() {
    const { pageContext, data } = this.props
    const { areas2XlsxAreas } = data
    const { title, slug, theme } = pageContext
    const LGAName = areas2XlsxAreas.Name
    const LGASlug = pageContext.LGASlug
    const LGALongName = areas2XlsxAreas.LongName
    const alias = areas2XlsxAreas.Alias
    const benchmarkName = pageContext.benchmarkName

    // hack, need to fix the periods in the import so that we import based on available data
    if (LGASlug == 'randwick' || LGASlug == 'mitchell') {
      data.medianPrices.nodes[0].data.Rentals.Periods = data.medianPrices.nodes[0].data.Rentals.Periods.filter(
        p => p.Period_Name != 'Jun 2017'
      )
      data.medianPrices.nodes[0].data.Sales.Periods = data.medianPrices.nodes[0].data.Sales.Periods.filter(
        p => p.Period_Name != 'Jun 2017'
      )
    }

    return (
      <PageContentWrapper
        title={title}
        LGAName={LGALongName}
        theme={theme}
        areas={areas2XlsxAreas}
        slug={slug}
        alias={alias}
        productLinks={pageContext.productLinks}
        clientLogo={this.props.data.allFile}
      >
        <SectionUpfrontText title="Household income" type="h2">
          <p>
            Looking at income data relative to the benchmark gives an insight into the
            socio-economic status of the local area, and provides a direct comparison to house
            prices and rental costs in the region.
          </p>
          <p>
            The incomes are shown compared to four ranges which are defined relative to incomes in
            the benchmark {benchmarkName} as follows:
          </p>
          <ul>
            <li>Very low (&#60; 50% of median income)</li>
            <li>Low (50% to 80% of median income)</li>
            <li>Moderate (80% to 120% of median income)</li>
            <li>High and very high (over 120% of median income)</li>
          </ul>
          <p>
            The median income is calculated for the {benchmarkName} area, and the local income
            distribution is shown relative to that. These ranges are used because they are accepted
            as standard for the calculation of housing need in Australia, with housing stress and
            availability of affordable housing also shown for the very low, low and moderate income
            levels in other parts of the tool.
          </p>
          <p>
            Data shown here are sourced from the Census, and the exact ranges used can be found in
            the data notes. The mix of incomes should be viewed in conjunction with rent and house
            price data, and availability of affordable housing to those income levels, to build up a
            picture of the housing need.
          </p>
        </SectionUpfrontText>
        <div className="row">
          <div className="col-12 anchor-point" id="what-is-the-mix-of-incomes">
            <IncomeMixChart
              data={data.incomeBands}
              benchmarkData={data.incomeBandsBenchmark}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              dataNotes={DynamicText(
                <IncomeMixNote
                  incomeRangesByYear={data.incomeRangesByYear}
                  benchmarkName={benchmarkName}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <IncomeChangeChart
              data={data.medianIncome}
              benchmarkData={data.bmMedianIncome}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              chartInfo={DynamicText(<IncomeChangeNote LGAName={LGAName} />)}
              dataNotes={data.incomeChangeNotes}
            />
          </div>
        </div>
        <SectionUpfrontText title="Buying a home" type="h2">
          <p>
            Home purchase has been known as the “Australian dream”, but increasing housing prices in
            many parts of the country have made this more difficult in recent years. The charts
            below compare {getEntryLevelLabel(LGASlug).toLowerCase()} , median and upper level
            housing prices in {LGAName} to those for {benchmarkName}. Sourced from PropTrack (REA
            Group), housing prices represent all sales over the 3-month period ending in the listed
            month, and show how affordable housing is in the area compared to the benchmark. Housing
            prices are updated every 6-months, with a 5 year rolling comparison.
          </p>
          <p>
            {getEntryLevelLabel(LGASlug)} housing (25th percentile) represents the amount a buyer
            would pay in {LGAName} to get a foothold in the market, and is a good indication of
            first home buyer housing stock. The median (50th percentile) is a good measure of the
            value of all housing, as it is the midpoint, and is the most quoted figure on housing
            affordability in any area. The upper level (75th percentile) represents what a buyer
            would be paying to significantly upgrade their housing in {LGAName} to a larger or
            better located dwelling. Houses and units can have quite different distributions of
            housing costs so they are shown separately.
          </p>
          <p>
            The second and third charts show prices for houses and units in {LGAName} compared to{' '}
            {benchmarkName} as a time series, and optional change in percentage over a 12 month
            period. This is useful to show the level of increase or decrease and should be compared
            to the income ranges above, and the direct availability calculations in the
            Affordability and Availability page, to get a clear picture of how housing affordability
            is changing over time in {LGAName}. The chart can be toggled between median, and entry
            level as needed, while the table shows both.
          </p>
        </SectionUpfrontText>
        <div className="row">
          <div className="col-12 anchor-point" id="how-do-housing-prices-compare">
            <PricePointsSaleChart
              alias={this.props.pageContext.LGASlug}
              data={data.medianPrices.nodes[0].data.Sales.Periods}
              dataBM={data.bmPricePointsRentalSales}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              dataNotes={data.pricePointsNotes}
            />
          </div>
        </div>
        <div className="row">
          {/* For House price changes */}
          <div className="col-12" id="how-have-house-prices-been-changing">
            <GenericPriceChangeChart
              data={data.medianPrices.nodes[0].data.Sales.Periods}
              propertyType={'House'}
              transactionType={'Sales'}
              benchmarkData={data.bmPricePointsRentalSales}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              name={'house-sale-change'}
              title={'How have house prices been changing?'}
              subTitle={
                'Quarterly median and entry-level (25th percentile) housing prices (and rolling 12-month % change)'
              }
              dataNotes={DynamicText(
                <DwellingChangeNote LGAName={LGAName} benchmarkName={benchmarkName} />
              )}
              chartTypeText={'house prices'}
            />
          </div>
        </div>
        <div className="row">
          {/* For Unit price changes */}
          <div className="col-12" id="how-have-unit-prices-been-changing">
            <GenericPriceChangeChart
              data={data.medianPrices.nodes[0].data.Sales.Periods}
              propertyType={'Unit'}
              transactionType={'Sales'}
              benchmarkData={data.bmPricePointsRentalSales}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              dataNotes={DynamicText(
                <DwellingChangeNote LGAName={LGAName} benchmarkName={benchmarkName} />
              )}
              name={'unit-sale-change'}
              title={`How have unit prices been changing?`}
              subTitle={`Quarterly median and entry-level (25th percentile) unit prices (and rolling 12-month % change)`}
              chartTypeText={'unit prices'}
            />
          </div>
        </div>
        <SectionUpfrontText title="Renting a home" type="h2">
          <p>
            Renting a home in Australia is generally the preferred tenure among those with low
            incomes, young people and those who need housing mobility. Renting is a more “pure”
            measure of the time value of housing in an area than housing prices, as it factors in
            current demand and supply, with no ownership premium.
          </p>
          <p>
            The charts below compare {getEntryLevelLabel(LGASlug).toLowerCase()} , median and upper
            level rental prices in {LGAName} to those for {benchmarkName}. Sourced from PropTrack
            (REA Group), rents represent all leases entered into over the 3-month period ending in
            the listed month, and show how affordable housing is to rent in the area compared to the
            benchmark. Rental prices are updated every 6-months, with a 5 year rolling comparison.
          </p>
          <p>
            {getEntryLevelLabel(LGASlug)} housing (25th percentile) represents the amount a tenant
            would pay in {LGAName} to get a basic dwelling to rent and provide shelter, and is a
            good indication of smaller rental housing away from premium locations. The median (50th
            percentile) is a good measure of the average cost to rent in {LGAName},as it is the
            midpoint, and is the most quoted figure on rental affordability in any area. The upper
            level (75th percentile) represents what an aspirational or upgrading renter would be
            paying in the area – for instance a high income individual who does not want to buy, or
            a family looking for rental accommodation with many rooms for children. The difference
            in these price points can show the diversity of rental stock in the area – a large
            interquartile range indicates a lot of rental diversity, but minimal difference between
            entry and upper level can indicate rental stock which is primarily focussed on one
            socio-economic segment.
          </p>
          <p>
            The second and third charts show weekly rents for houses and units in {LGAName}
            compared to {benchmarkName} as a time series on a quarterly basis, for quarters ended
            March, June, September and December - with optional change in percentage over a 12 month
            period. This is useful to show the level of increase or decrease and should be compared
            to the income ranges above, and the direct availability calculations in the
            Affordability and Availability page, to get a clear picture of how rental affordability
            is changing over time in {LGAName}. The chart can be toggled between median, and entry
            level as needed, while the table shows both. Median is the 50th percentile while entry
            level is the 25th percentile and is indicative of the lower range that a renter would
            need to pay to access housing in the area.
          </p>
        </SectionUpfrontText>
        <div className="row anchor-point" id="how-do-rental-costs-compare">
          <div className="col-12">
            <PricePointsRentalChart
              alias={this.props.pageContext.LGASlug}
              data={data.medianPrices.nodes[0].data.Rentals.Periods}
              dataBM={data.bmPricePointsRentalSales}
              areaName={LGAName}
              dataNotes={data.pricePointsRentalNotes}
              benchmarkName={benchmarkName}
            />
          </div>
        </div>
        <div className="row">
          {/* For House rental price changes */}
          <div className="col-12" id="how-have-house-rental-prices-been-changing">
            <GenericPriceChangeChart
              data={data.medianPrices.nodes[0].data.Rentals.Periods}
              propertyType={'House'}
              transactionType={'Rentals'}
              benchmarkData={data.bmPricePointsRentalSales}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              dataNotes={DynamicText(
                <RentPriceChangeNote LGAName={LGAName} benchmarkName={benchmarkName} />
              )}
              name={'house-rental-change'}
              title={'How have house rental prices been changing?'}
              subTitle={
                'Quarterly median and entry-level (25th percentile) housing rental prices (and rolling 12-month % change)'
              }
              chartTypeText={'house rents'}
            />
          </div>
        </div>
        <div className="row">
          {/* For Unit rental price changes */}
          <div className="col-12" id="how-have-unit-rental-prices-been-changing">
            <GenericPriceChangeChart
              data={data.medianPrices.nodes[0].data.Rentals.Periods}
              propertyType={'Unit'}
              transactionType={'Rentals'}
              benchmarkData={data.bmPricePointsRentalSales}
              areaName={LGAName}
              benchmarkName={benchmarkName}
              dataNotes={DynamicText(
                <RentPriceChangeNote LGAName={LGAName} benchmarkName={benchmarkName} />
              )}
              name={'unit-rental-change'}
              title={'How have unit rental prices been changing?'}
              subTitle={
                'Quarterly median and entry-level (25th percentile) unit rental prices (and rolling 12-month % change)'
              }
              chartTypeText={'unit rents'}
            />
          </div>
        </div>
      </PageContentWrapper>
    )
  }
}

PricesIncomesTemplate.propTypes = {
  data: PropTypes.shape({}),
}

PricesIncomesTemplate.defaultProps = {
  data: {},
}

export default PricesIncomesTemplate

export const PageQuery = graphql`
  query PricesIncomes($lgacode: String!, $benchmarkCode: String!, $geocode: String!) {
    allFile(filter: { name: { eq: $geocode } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }

    incomeBands: incomeBandsXlsxSheet1(Area_Id: { eq: $lgacode }, Year: { eq: 2021 }) {
      v_low_perc
      low_perc
      mod_perc
      high_perc
      v_low_num
      low_num
      mod_num
      high_num
    }

    incomeBandsBenchmark: incomeBandsXlsxSheet1(
      Area_Id: { eq: $benchmarkCode }
      Year: { eq: 2021 }
    ) {
      v_low_perc
      low_perc
      mod_perc
      high_perc
    }

    incomeRangesByYear: allAffordabilityBands(filter: { gccsa: { eq: $benchmarkCode } }) {
      nodes {
        data {
          Incomes {
            Couple {
              Low
              Moderate
              Very_Low
              Label
            }
            Couple_family_with_children {
              Low
              Moderate
              Very_Low
              Label
            }
            Lone_person {
              Low
              Moderate
              Very_Low
              Label
            }
            One_parent_family {
              Low
              Moderate
              Very_Low
              Label
            }
            Group_household {
              Low
              Moderate
              Very_Low
              Label
            }
            Total {
              Low
              Moderate
              Very_Low
              Label
            }
            Year
          }
          Interest_rate
        }
      }
    }

    bmPricePointsRentalSales: allRentalAndSalesByGccsaCsvSheet1(
      filter: { GCCSACode: { eq: $benchmarkCode } }
      sort: { fields: [Period], order: DESC }
    ) {
      edges {
        node {
          ListingType
          GCCSACode
          StructureName
          PropertyType
          Percentile25
          Median
          Percentile75
          Period
        }
      }
    }

    medianPrices: allPricesIncomesMedians(filter: { lga: { eq: $lgacode } }) {
      nodes {
        data {
          Rentals {
            Periods {
              Median_Unit
              Median_House
              Entry_Unit
              Entry_House
              Upper_House
              Upper_Unit
              Period_Name
            }
          }
          Sales {
            Periods {
              Median_Unit
              Median_House
              Entry_Unit
              Entry_House
              Upper_House
              Upper_Unit
              Period_Name
            }
          }
        }
      }
    }

    medianIncome: medianIncomeXlsxResult(Area_Id: { eq: $lgacode }) {
      LGACode
      LGA_Name
      MedHHI_06
      MedHHI_11
      MedHHI_16
      MedHHI_21
      Income_change_last5
    }

    bmMedianIncome: medianIncomeXlsxResult(Area_Id: { eq: $benchmarkCode }) {
      LGACode
      LGA_Name
      MedHHI_06
      MedHHI_11
      MedHHI_16
      MedHHI_21
      Income_change_last5
    }

    areas2XlsxAreas(Area_Id: { eq: $lgacode }) {
      Name
      LongName
      Geocode
      Area_Id
      Alias
    }

    incomeChangeNotes: markdownRemark(
      frontmatter: { slug: { eq: "income-change" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }
    pricePointsNotes: markdownRemark(
      frontmatter: { slug: { eq: "price-points" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    pricePointsRentalNotes: markdownRemark(
      frontmatter: { slug: { eq: "price-points-rental" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    priceChangeNotes: markdownRemark(
      frontmatter: { slug: { eq: "price-change" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }

    annualPriceGrowthNotes: markdownRemark(
      frontmatter: { slug: { eq: "annual-price-growth" }, type: { eq: "full-text" } }
    ) {
      ...DataNoteFragment
    }
  }
`
