import React, { useEffect } from 'react'
import { filter, isEmpty, head} from 'lodash'
import { navigate } from 'gatsby'
import { graphql } from 'gatsby'
import numeral from 'numeral'
import { passwordProtected } from '../config/password-proctected'
import PageContentWrapper from '../components/pageContentWrapper'
import HousingStressRentalChart from '../components/charts/housing-stress-rental'
import HousingStressMortgageChart from '../components/charts/housing-stress-mortgage'
import HousingStressMortgagePercentageChart from '../components/charts/housing-stress-mortgage-percentage'
import HousingStressRentalPercentageChart from '../components/charts/housing-stress-rental-percentage'
import HousingStressStackedChart from '../components/charts/housing-stress-stacked'
import NumberPanel from '../components/charts/number-panel'
import * as Datasource from '../config/text-constants'
import { AvailabilityContext } from '../hooks/availabilityContext'
import HousingStressNote from '../components/dynamicText/stress-and-need/housing-stress-datanote'
import SectionUpfrontText from '../components/collapsibleUpfrontText/section-upfront-text'
import DynamicText from '../components/dynamicText'
import HousingStressRentalByIncomeMap from '../components/maps/housingStressRentalByIncome'
import HousingStressMortgageByIncomeMap from '../components/maps/housingStressMortgageByIncome'

const StressTemplate = props => {
  useEffect(() => {
    const access = head(filter(passwordProtected, ['lga', pageContext.LGASlug]))
    if (!isEmpty(access) && access.password !== window.pass) {
      navigate(`/${pageContext.LGASlug}`)
    }
  })

  // Destructure passed in object
  const data = props.data
  const pageContext = props.pageContext

  const title = pageContext.title
  const slug = pageContext.slug
  const LGAName = data.areas2XlsxAreas.Name
  const LGACode = data.areas2XlsxAreas.Area_Id
  const LGALongName = data.areas2XlsxAreas.LongName
  const alias = data.areas2XlsxAreas.Alias
  const benchmarkName = pageContext.benchmarkName

  const totalHouseholds = () => {
    try {
      return data.households.total_21
    } catch (error) {
      console.error(error)
      return 0
    }
  }
  const totalRenting = () => {
    try {
      return data.tenure.N2021_Rented_Private + data.tenure.N2021_Rented_Social
    } catch (error) {
      console.error(error)
      return 0
    }
  }
  const totalMortgage = () => {
    try {
      return data.tenure.N2021_Owned_With_Mortgage
    } catch (error) {
      console.error(error)
      return 0
    }
  }
  const totalInHousingStress = () => {
    try {
      return data.housingStress.Total_Rental_Stress_No + data.housingStress.Total_Mortgage_Stress_No
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  const totalRentalStressNo = () => {
    try {
      return data.housingStress.Total_Rental_Stress_No
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  const totalRentalStressPerc = () => {
    try {
      return data.housingStress.Total_Rental_Stress_Per
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  const totalInHousingStressPerc = () => {
    try {
      return totalInHousingStress() / totalHouseholds()
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  const totalMortgageStressNo = () => {
    try {
      return data.housingStress.Total_Mortgage_Stress_No
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  const totalMortgageStressPerc = () => {
    try {
      return data.housingStress.Total_Mortgage_Stress_Per
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  const totalMortgageStressBenchmarkPerc = () => {
    try {
      return data.benchmarkHousingStress.Total_Mortgage_Stress_Per
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  const totalRentalStressBenchmarkPerc = () => {
    try {
      return data.benchmarkHousingStress.Total_Rental_Stress_Per
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  const compareToBenchmark = (percentage, benchmarkPercentage) => {
    if (percentage === benchmarkPercentage) return 'equal to'
    if (percentage > benchmarkPercentage) return 'higher than'
    return 'lower than'
  }

  const MortgageIntro = () => {
    return (
      <p className="lead">
        Of the {numeral(totalMortgage()).format('0,0')} households with a mortgage in {LGAName},{' '}
        {numeral(totalMortgageStressNo()).format('0,0')} (
        {numeral(totalMortgageStressPerc()).format('0[.]0')}%) are in mortgage stress. This is{' '}
        {compareToBenchmark(totalMortgageStressPerc(), totalMortgageStressBenchmarkPerc())} the{' '}
        {benchmarkName} average.
      </p>
    )
  }

  const RentalIntro = () => {
    return (
      <p className="lead">
        Of the {numeral(data.tenure.N2021_Rented_Private).format('0,0')} households in private rentals in {LGAName},{' '}
        {numeral(totalRentalStressNo()).format('0,0')} (
        {numeral(totalRentalStressPerc()).format('0[.]0')}%) are in rental stress. This is{' '}
        {compareToBenchmark(totalRentalStressPerc(), totalRentalStressBenchmarkPerc())} the{' '}
        {benchmarkName} average.
      </p>
    )
  }

  return (
    <AvailabilityContext.Consumer>
      {context => (
        <React.Fragment>
          <PageContentWrapper
            title={title}
            LGAName={LGALongName}
            theme={pageContext.theme}
            areas={data.areas2XlsxAreas}
            slug={slug}
            alias={alias}
            currentArea={LGACode}
            productLinks={pageContext.productLinks}
            clientLogo={data.allFile}
          >
            <h2 className="section-heading mt-0" id="housing-stress">
              Housing stress
            </h2>

            <SectionUpfrontText title="What is housing stress?">
              <div>
                <p>
                  Housing stress is a specific term which refers to households having trouble
                  meeting their financial housing obligations – rent or mortgage payments. For those
                  who are purchasing or renting their dwellings, we use the definition of housing
                  stress used by some State Governments across Australia, which is{' '}
                  <strong>
                    households in prescribed income brackets, spending more than 30% of their gross
                    household income on either rent or mortgage repayments.{' '}
                  </strong>
                  Income brackets for this definition are classified as Very Low (&#60; 50% of
                  median), Low (50% to 80% of median) and Moderate (80% to 120% of median).
                  Households on high incomes are not counted as being in housing stress, even if
                  payments are above 30% of income, as this is more likely to contain an element of
                  choice (eg. Paying extra on a mortgage to complete the loan sooner).
                </p>
                <p>
                  Housing stress is the most tangible measure of how many households in an area may
                  be in financial difficulties, and directly correlates to affordability – if many
                  households are in housing stress, it directly indicates that housing is
                  unaffordable, but many people may have a necessity to live in the area, and so are
                  paying this anyway. It does not include people who may have chosen not to live in
                  the area BECAUSE it is unaffordable. Availability of affordable housing can give
                  an insight into this measure.
                </p>
                <p>
                  Housing stress is expressed as a percentage of total households, i.e. includes
                  full home owners) and so is often lower than either mortgage or rental stress on
                  its own.
                </p>
              </div>
            </SectionUpfrontText>

            <div className="row">
              <div className="col-md-6">
                <div>
                  <NumberPanel title="Total households 2021" source={Datasource.ABS2021}>
                    <span className="h2">{numeral(totalHouseholds()).format('0,0')}</span>
                  </NumberPanel>
                </div>
                <div>
                  <NumberPanel title="Households in housing stress" source={Datasource.ABS2021}>
                    <span className="h2">{numeral(totalInHousingStress()).format('0,0')}</span>{' '}
                    <span>({numeral(totalInHousingStressPerc()).format('0[.]0%')})</span>
                  </NumberPanel>
                </div>
              </div>

              <div className="col-md-6">
                <HousingStressStackedChart
                  data={{
                    housingStress: data.housingStress,
                    totalHouseholds: totalHouseholds(),
                    totalRenting: totalRenting(),
                    totalMortgage: totalMortgage(),
                  }}
                  areaName={LGAName}
                  dataNotes={
                    DynamicText(
                      <HousingStressNote incomeRangesByYear={data.incomeRangesByYear} benchmarkName={benchmarkName}/>
                    )
                  }
                />
              </div>
            </div>

            <SectionUpfrontText title="With a mortgage" type="h2" id="mortgage-stress">
              <div>
                <p>
                  Mortgage Stress measures the number of households who have a mortgage and are
                  paying more than 30% of gross household income on mortgage payments, who are in
                  the Very Low, Low or Moderate income ranges. High income ranges are excluded as
                  described above. Percentages are calculated from the total number of households
                  with a mortgage.
                </p>
                <p>
                  High mortgage stress can be an indicator of housing being unaffordable for first
                  home buyers, on low incomes, or that there are few other options to home purchase.
                  Upgraders may also go into mortgage stress, but a larger share of these are likely
                  to be in the higher income bracket and are not counted. A low level of mortgage
                  stress may not mean that housing is affordable, however, it could just mean that
                  lower incomes are priced out of the area entirely.
                </p>
              </div>
            </SectionUpfrontText>
            <div className="row">
              <div className="col-12">
                <MortgageIntro />
              </div>

              <div className="col-md-6">
                <HousingStressMortgagePercentageChart
                  data={data.housingStress}
                  benchmarkData={data.benchmarkHousingStress}
                  areaName={LGAName}
                  benchmarkName={benchmarkName}
                  dataNotes={
                    DynamicText(
                      <HousingStressNote incomeRangesByYear={data.incomeRangesByYear} benchmarkName={benchmarkName}/>
                    )
                  }
                />
              </div>

              <div className="col-md-6">
                <HousingStressMortgageChart
                  data={data.housingStress}
                  benchmarkData={data.benchmarkHousingStress}
                  areaName={LGAName}
                  benchmarkName={benchmarkName}
                  dataNotes={
                    DynamicText(
                      <HousingStressNote incomeRangesByYear={data.incomeRangesByYear} benchmarkName={benchmarkName}/>
                    )
                  }
                />
              </div>
              <div className="col-12 anchor-point" id="where-is-mortgage-stress-felt">
                <HousingStressMortgageByIncomeMap
                  data={data.sa2_housing_stress}
                  pageContext={pageContext}
                />
              </div>
            </div>

            <SectionUpfrontText title="Renting" type="h2" id="rental-stress">
              <div>
                <p>
                  Rental Stress measures the number of households who are renting in the private
                  market, and are paying more than 30% of gross household income on weekly or
                  monthly rent payments, who are in the Very Low, Low or Moderate income ranges.
                  High income ranges are excluded as described above. The vast majority of renters
                  fall into the lower to moderate income ranges. Percentages are calculated as a
                  proportion of total renters.
                </p>
                <p>
                  As rent is a more “pure” measure of the ongoing value of shelter (not as subject
                  to speculative investment or emotional decisions pushing up prices) , rental
                  stress can be an indicator of housing being unaffordable to rent in the area, when
                  it is the only option for lower income earners. Where buying a home is not an
                  option, rent may be an alternative but large numbers of people being priced out of
                  home ownership can also create a large pool of renters competing for supply,
                  pushing prices up and forcing households into rental stress. A low level of rental
                  stress is likely to indicate that housing is affordable for those who really need
                  it, or that renting is not a major tenure type in the area.
                </p>
                <p>
                  Rental stress information should be viewed in conjunction with affordable renting
                  availability information.
                </p>
              </div>
            </SectionUpfrontText>
            <div className="row align-content-around">
              <div className="col-12">
                <RentalIntro />
              </div>
              <div className="col-md-6">
                <HousingStressRentalPercentageChart
                  data={data.housingStress}
                  benchmarkData={data.benchmarkHousingStress}
                  areaName={LGAName}
                  benchmarkName={benchmarkName}
                  dataNotes={
                    DynamicText(
                      <HousingStressNote incomeRangesByYear={data.incomeRangesByYear} benchmarkName={benchmarkName}/>
                    )
                  }
                />
              </div>

              <div className="col-md-6">
                <HousingStressRentalChart
                  data={data.housingStress}
                  benchmarkData={data.benchmarkHousingStress}
                  areaName={LGAName}
                  benchmarkName={benchmarkName}
                  dataNotes={
                    DynamicText(
                      <HousingStressNote incomeRangesByYear={data.incomeRangesByYear} benchmarkName={benchmarkName}/>
                    )
                  }
                />
              </div>

              <div className="col-12 anchor-point" id="where-is-rental-stress-felt">
                <HousingStressRentalByIncomeMap
                  data={data.sa2_housing_stress}
                  pageContext={pageContext}
                />
              </div>
            </div>
          </PageContentWrapper>
        </React.Fragment>
      )}
    </AvailabilityContext.Consumer>
  )
}

export default StressTemplate


export const PageQuery = graphql`
  query HousingNeed(
    $lgacode: String!
    $fullLgaListPrefix: [String]
    $benchmarkCode: String!
    $geocode: String!
  ) {
    allFile(filter: { name: { eq: $geocode } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }

    housingStress: housingStressNeed21XlsxHousingStress(Area_Id: { eq: $lgacode }) {
      Very_Low_Mortgage_Stress_No
      Very_Low_Mortgage_Stress_Per
      Low_Mortgage_Stress_No
      Low_Mortgage_Stress_Per
      Moderate_Mortgage_Stress_No
      Moderate_Mortgage_Stress_Per
      Very_Low_Rental_Stress_No
      Very_Low_Rental_Stress_Per
      Low_Rental_Stress_No
      Low_Rental_Stress_Per
      Moderate_Rental_Stress_No
      Moderate_Rental_Stress_Per
      Total_Mortgage_Stress_No
      Total_Mortgage_Stress_Per
      Total_Rental_Stress_No
      Total_Rental_Stress_Per
    }
    benchmarkHousingStress: housingStressNeed21XlsxHousingStress(Area_Id: { eq: $benchmarkCode }) {
      Very_Low_Mortgage_Stress_No
      Very_Low_Mortgage_Stress_Per
      Low_Mortgage_Stress_No
      Low_Mortgage_Stress_Per
      Moderate_Mortgage_Stress_No
      Moderate_Mortgage_Stress_Per
      Very_Low_Rental_Stress_No
      Very_Low_Rental_Stress_Per
      Low_Rental_Stress_No
      Low_Rental_Stress_Per
      Moderate_Rental_Stress_No
      Moderate_Rental_Stress_Per
      Total_Mortgage_Stress_No
      Total_Mortgage_Stress_Per
      Total_Rental_Stress_No
      Total_Rental_Stress_Per
    }
    sa2_housing_stress: allHousingStressSa2XlsxResult(
      filter: { LGACode : { in: $fullLgaListPrefix } }
    ) {
      edges {
        node {
          Area_Id
          Very_Low_Mortgage_Stress_No
          Very_Low_Mortgage_Stress_Per
          Low_Mortgage_Stress_No
          Low_Mortgage_Stress_Per
          Moderate_Mortgage_Stress_No
          Moderate_Mortgage_Stress_Per
          Very_Low_Rental_Stress_No
          Very_Low_Rental_Stress_Per
          Low_Rental_Stress_No
          Low_Rental_Stress_Per
          Moderate_Rental_Stress_No
          Moderate_Rental_Stress_Per
          Total_Mortgage_Stress_No
          Total_Mortgage_Stress_Per
          Total_Rental_Stress_No
          Total_Rental_Stress_Per
        }
      }
    }

    households: householdsXlsxHouseholdTypes(Area_Id: { eq: $lgacode }) {
      total_21
    }
    tenure: householdsXlsxTenure(Area_Id: { eq: $lgacode }) {
      N2021_Rented_Private
      Per2016_Rented_Private
      N2021_Rented_Social
      Per2016_Rented_Social
      N2021_Owned_Outright
      Per2016_Owned_Outright
      N2021_Owned_With_Mortgage
      Per2016_Owned_With_Mortgage
      N2021_Total
      N2011_Rented_Private
      Per2011_Rented_Private
      N2011_Rented_Social
      Per2011_Rented_Social
      N2011_Owned_Outright
      Per2011_Owned_Outright
      N2011_Owned_With_Mortgage
      Per2011_Owned_With_Mortgage
      N2011_Total
    }
    areas2XlsxAreas(Area_Id: { eq: $lgacode }) {
      Name
      LongName
      Geocode
      Area_Id
      Alias
    }

    incomeRangesByYear: allAffordabilityBands(filter: { gccsa: { eq: $benchmarkCode } }) {
      nodes {
        data {
          Incomes {
            Couple {
              Low
              Moderate
              Very_Low
              Label
            }
            Couple_family_with_children {
              Low
              Moderate
              Very_Low
              Label
            }
            Lone_person {
              Low
              Moderate
              Very_Low
              Label
            }
            One_parent_family {
              Low
              Moderate
              Very_Low
              Label
            }
            Group_household {
              Low
              Moderate
              Very_Low
              Label
            }
            Total {
              Low
              Moderate
              Very_Low
              Label
            }
            Year
          }
          Interest_rate
        }
      }
    }
    unusedButStopsBuildFromBreaking: markdownRemark {
      ...DataNoteFragment
    }
  }
`
